<template>
  <v-bottom-sheet v-model="sheet" persistent light>
    <v-sheet tile>
      <v-list three-line>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="headline">Cookie Consent</v-list-item-title>
            <v-list-item-subtitle>
              This website uses cookies to ensure you get the best experience,
              <a
                target="_blank"
                alt="Odro Compliance"
                title="Go to Odro Compliance"
                href="https://www.odro.co.uk/compliance/product-privacy-policy/"
              >
                learn more
              </a>
              .
            </v-list-item-subtitle>
          </v-list-item-content>
          <template v-if="!mobileView">
            <v-spacer></v-spacer>
            <v-list-item-action>
              <v-btn color="warning" large @click="accept()">Reject</v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn color="success" large @click="accept()">Im OK with that</v-btn>
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list>
      <v-list v-if="mobileView" dense>
        <v-list-item>
          <v-row justify="center" class="mb-1">
            <v-btn color="warning" class="ma-2" @click="accept()">Reject</v-btn>
            <v-btn color="success" class="ma-2" @click="accept()">Im OK with that</v-btn>
          </v-row>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  data() {
    return {
      cookieName: 'cookie-consent',
      loading: true,
      sheet: false,
    };
  },
  computed: {
    mobileView() {
      // this will probably get a little more complex going forward but this will do for now
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    const consent = this.getCookie(this.cookieName);
    if (consent === 'allow') {
      this.loading = false;
      this.sheet = false;
      this.$emit('cookieAllowed');
    } else {
      this.loading = false;
      this.sheet = true;
    }
  },
  methods: {
    accept() {
      this.setCookie(this.cookieName, 'allow', 365);
      this.sheet = false;
      this.$emit('cookieAllowed');
    },
    getCookie(name) {
      const value = '; ' + document.cookie;
      const parts = value.split('; ' + name + '=');
      return parts.length < 2 ? undefined : parts.pop().split(';').shift();
    },
    setCookie(name, value, expiryDays, domain, path, secure) {
      const exdate = new Date();
      exdate.setDate(exdate.getDate() + (expiryDays || 365));

      const cookie = [name + '=' + value, 'expires=' + exdate.toUTCString(), 'path=' + (path || '/')];

      if (domain) {
        cookie.push('domain=' + domain);
      }
      if (secure) {
        cookie.push('secure');
      }
      document.cookie = cookie.join(';');
    },
  },
};
</script>
